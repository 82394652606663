.App {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "Nanum-Light";
  src: url("./assets/fonts/SquareNeo/NanumSquareNeoOTF-aLt.otf") format("opentype")
}

@font-face {
  font-family: "Nanum-Regular";
  src: url("./assets/fonts/SquareNeo/NanumSquareNeoOTF-bRg.otf") format("opentype")
}

@font-face {
  font-family: "Nanum-Bold";
  src: url("./assets/fonts/SquareNeo/NanumSquareNeoOTF-eHv.otf") format("opentype")
}

@font-face {
  font-family: "Nanum-Extra";
  src: url("./assets/fonts/SquareNeo/NanumSquareNeoOTF-dEb.otf") format("opentype")
}

@font-face {  font-family: 'Pretendard';  font-weight: 900;  font-display: block;  src: local('Pretendard Black'), url('../src/assets/fonts/Pretendard/Pretendard-Black.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 800;  font-display: block;  src: local('Pretendard ExtraBold'), url('../src/assets/fonts/Pretendard/Pretendard-ExtraBold.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 700;  font-display: block;  src: local('Pretendard Bold'), url('../src/assets/fonts/Pretendard/Pretendard-Bold.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 600;  font-display: block;  src: local('Pretendard SemiBold'), url('../src/assets/fonts/Pretendard/Pretendard-SemiBold.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 500;  font-display: block;  src: local('Pretendard Medium'), url('../src/assets/fonts/Pretendard/Pretendard-Medium.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 400;  font-display: block;  src: local('Pretendard Regular'), url('../src/assets/fonts/Pretendard/Pretendard-Regular.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 300;  font-display: block;  src: local('Pretendard Light'), url('../src/assets/fonts/Pretendard/Pretendard-Light.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 200;  font-display: block;  src: local('Pretendard ExtraLight'), url('../src/assets/fonts/Pretendard/Pretendard-ExtraLight.woff2') format('woff2');  }
@font-face {  font-family: 'Pretendard';  font-weight: 100;  font-display: block;  src: local('Pretendard Thin'), url('../src/assets/fonts/Pretendard/Pretendard-Thin.woff2') format('woff2');  }

html {
}

body {
    margin: 0;
    font-family: 'Pretendard', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*드래그 방지*/
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* 이미지 드래그 방지 */

img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

p {
    margin: 0;
    padding: 0;
}

*::-webkit-scrollbar {width: 5px; height: 5px}
*::-webkit-scrollbar-track {background: transparent;}
*::-webkit-scrollbar-thumb {background-color: #ddd;border-radius: 20px;}
*::-webkit-scrollbar-thumb:hover {background-color: gray;}




input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=radio] {accent-color: #006868;}


input::placeholder {
    font-size: 14px;
}

textarea {
    font-family: 'Pretendard', sans-serif;
    resize: none;
    outline: none;
}


textarea::placeholder {
    font-size: 14px;

}











